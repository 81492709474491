
import { defineComponent, ref, inject } from "vue";
import dateFormat from "@/utils/dateFormat";
import OrderItem from "@/components/common/OrderItem.vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import list from "@/components/common/list.vue";
import router from "@/router";
export default defineComponent({
  name: "Order",
  components: {
    OrderItem,
    list,
  },

  setup() {
    const route = useRoute();
    const code = route.query.code
    const state = route.query.state

    const clearUserInfo = inject("CLEAR_USERINFO") as () => void;
    function outLogin() {
      localStorage.removeItem("token");
      clearUserInfo();
      router.push({
        name: "Index",
      });
    }
    return {
      dateFormat,
      outLogin,
      code,
      state
    };
  },
});
